import React from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { FETCHING } from '../helpers/constants'
import CateringOrderItem from '../components/CateringOrderItem'
import PrintCateringOrder from '../components/PrintCateringOrder'
import Arrow from '../icons/Arrow'
import styles from './Catering.module.css'
import CateringMenu from '../components/CateringMenu'
import CateringOrderLineItem from '../components/CateringOrderLineItem'
import Alert from '../components/Alert'
import Modal from '../components/Modal'
import CloseIcon from '../icons/Close'
import MarketPlaceIcon from '../icons/MarketPlace'
import PrintIcon from '../icons/Print'
import CateringProductionList from '../components/CateringProductionList'
import { getDayOfWeek } from '../helpers/getWeekday'
import SearchOrder from '../components/SearchOrder'
import { Dispatch, RootState } from '../models'
import type { CateringOrderItem as OrderItem, Order } from '../types/Order'
import logError from '../utils/logging'
import Banner from '../components/Banner'
import TextButton from '../components/TextButton'
import PrintCateringProductionList from '../components/PrintCateringProductionList'
import { groupProductionList } from '../helpers/groupProductionLists'
import getReprintStatusProductionLists, { ProductionListToReprint } from '../helpers/getReprintStatusProductionLists'

type Props = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>
function Catering ({
  fetchOrders,
  cancelOrder,
  printOrder,
  fetchUserStores,
  selectedStore,
  cateringOrders,
  status,
  userId
}: Props) {
  const [activeModal, setActiveModal] = React.useState<string | null>(null)
  const [openedFromSearch, setOpenedFromSearch] = React.useState(false)
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const [selectedView, setSelectedView] = React.useState(1)
  const { storeId } = useParams<string>()
  const printOrderRef = React.useRef<HTMLInputElement>(null)
  const [ordersToPrint, setOrdersToPrint] = React.useState<Order[]>([])
  const [ordersToReprint, setOrdersToReprint] = React.useState<Order[]>([])
  const [updatedOrdersModal, setUpdatedOrdersModal] = React.useState(false)
  const [productionListsToReprint, setProductionListsToReprint] = React.useState<ProductionListToReprint[]>([])
  const [productionListsByDate, setProductionListsByDate] = React.useState<ProductionListToReprint[]>([])
  const [groupedProductionLists, setGroupedProductionLists] = React.useState<OrderItem[][]>([])
  const [listsToReprint, setListsToReprint] = React.useState<ProductionListToReprint[]>([])
  const printListsRef = React.useRef<HTMLDivElement>(null)
  const abortController = new AbortController()

  const weekdays = React.useMemo(() => {
    return cateringOrders.map((order) => {
      return getDayOfWeek(order.date)
    })
  }, [cateringOrders])

  React.useEffect(() => {
    if (!selectedStore) {
      fetchUserStores(storeId)
    }
  }, [selectedStore])

  React.useEffect(() => {
    const signal = abortController.signal
    if (!signal.aborted && storeId) {
      getOrders()
    }

    return () => {
      abortController.abort()
    }
  }, [storeId])

  React.useEffect(() => {
    try {
      if (document && printOrderRef !== null && ordersToPrint.length > 0) {
        const printContent = printOrderRef.current?.innerHTML
        const frame = (document.getElementById('printframe') as HTMLIFrameElement).contentWindow
        if (frame !== null && printContent) {
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
        }

        const orderIdsToPrint = ordersToPrint.map((order) => {
          return order.id
        })

        printOrder({
          storeId,
          orderIds: orderIdsToPrint,
          userId: userId,
          isSingleOrder: false,
          type: 'IncomingOrders'
        })

        setOrdersToPrint([])
        setUpdatedOrdersModal(false)
      }
    } catch (error) {
      logError(error as Error)
    }
  }, [ordersToPrint])

  React.useEffect(() => {
    setOrdersToReprint([])
    const allOrders = cateringOrders.flatMap(obj => obj.orders)
    const editedOrders = allOrders.filter((order) => order.orderHistory && order.orderHistory.length > 0)

    editedOrders.forEach((order) => {
      const orderLastChanged = order.orderHistory && order.orderHistory[0].date 
      const printAction = order.printedByUsersWithType?.find(print => 
        print.actions?.some(action => action.type === "IncomingOrders" && print.userId === userId)
      )?.actions?.find(action => action.type === "IncomingOrders")

      if (orderLastChanged && 
        ((printAction && printAction.printedDateTime < orderLastChanged) 
        || (!printAction && order.printedByUser.includes(userId as string)))) {
        setOrdersToReprint((prevOrders) => [...prevOrders, order])
      } 
    })

    const groupedList = cateringOrders.reduce<OrderItem[][]>((acc, day) => {
      const { orders } = day
      const groupedDayOrder = groupProductionList(orders)
      return [...acc, groupedDayOrder]
    }, [])
    setGroupedProductionLists(groupedList)
    
    if (userId) {
      const productionListsToReprint = getReprintStatusProductionLists(groupedList, userId)
      setProductionListsToReprint(productionListsToReprint)
      groupProductionListByDate(productionListsToReprint)
    }
  }, [cateringOrders])

  React.useEffect(() => {
    getListsToPrint()
  }, [productionListsToReprint.length])

  function getListsToPrint () {
    const lists: OrderItem[][] = []

    productionListsByDate.forEach((date) => {
      groupedProductionLists.forEach((list) => {
        if (list[0].deliveryDate === date.date) {
          lists.push([...list])
        }
      })
    })
    const productionLists = lists.map(t => ({
      cateringProducts: [...t],
      date: t[0].deliveryDate
    }))
    setListsToReprint(productionLists)
  }

  function getOrders () {
    fetchOrders({
      storeId
    })
  }

  const groupProductionListByDate = (list: ProductionListToReprint[]) => {
    const listMap: Record<string, ProductionListToReprint> = {}
    list.forEach(item => {
      if (listMap[item.date]) {
        (listMap[item.date].orderNo as string[]).push(item.orderNo as string) 
        listMap[item.date].date = item.date 
      } else {
        listMap[item.date] = {
          ...item,
          orderNo: [item.orderNo as string],
          date: item.date
        }
      }
    })
    const groupedList = Object.values(listMap)
    setProductionListsByDate(groupedList)
  }
  

  function getIds (index: number, i: number) {
    let prevOrder: Order | null = cateringOrders[index].orders[i - 1]
    let nextOrder: Order | null = cateringOrders[index].orders[i + 1]

    if (!prevOrder) {
      const prevStep = cateringOrders[index - 1]
      if (prevStep) {
        prevOrder = prevStep.orders[prevStep.orders.length - 1]
      } else {
        prevOrder = null
      }
    }
    if (!nextOrder) {
      const nextStep = cateringOrders[index + 1]
      if (nextStep) {
        nextOrder = nextStep.orders[0]
      } else {
        nextOrder = null
      }
    }
    const prevId = prevOrder ? prevOrder.id : null
    const nextId = nextOrder ? nextOrder.id : null

    return {
      prevId,
      nextId
    }
  }

  function onOpenModalFromSearch (id: string) {
    setOpenedFromSearch(true)
    setActiveModal(id)
  }

  const reprintProductionLists = () => {
    try {
      if (document && printListsRef !== null) {
        const printContent = printListsRef.current?.innerHTML
        const frame = (document.getElementById('printframe') as HTMLIFrameElement).contentWindow
        if (frame !== null && printContent) {
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
        }
      }
      const idsToPrint = listsToReprint?.reduce((acc: any[], list) => {
        const ids = list.cateringProducts.flatMap(cateringProduct =>
          cateringProduct.products.flatMap(product =>
            product.orders.map(order => order.id)
          ))
        return [...acc,...ids]
      }, [] as string[])

      const uniqueIdsToPrint = [...new Set(idsToPrint)]
       
      printOrder({
        storeId,
        orderIds: uniqueIdsToPrint,
        userId: userId,
        isSingleOrder: false,
        type: 'ProductionList'
      })
      setUpdatedOrdersModal(false)

    } catch (error) {
      logError(error as Error)
    }
  }

  return (
    <div className={styles.history}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.backButtonBox}>
            <Link
              className={styles.backButton}
              to={`/butik/${storeId}/tjanster`}
            >
              <Arrow
                className={styles.backIcon}
                direction='left'
                color='#cf2e05'
              />
              Tillbaka
            </Link>
          </div>
          <div className={styles.selectedViewButtons}>
            <button
              onClick={() => setSelectedView(1)}
              className={
                selectedView === 1
                  ? styles.selectedView
                  : styles.notSelectedView
              }
            >
              <h1>Inkomna ordrar</h1>
            </button>
            <button
              onClick={() => { setSelectedView(2) }}
              className={
                selectedView === 2
                  ? styles.selectedView
                  : styles.notSelectedView
              }
            >
              <h1>Produktionslista</h1>
            </button>
          </div>
        </div>
      </header>
      {status === FETCHING ? (
        <div className={styles.loading}>
          <p>Laddar Cateringordrar…</p>
        </div>
      ) : cateringOrders.length > 0 ? (
        <>
          {(selectedView === 1 && ordersToReprint.length > 0) && (
            <div onClick={() => setUpdatedOrdersModal(true)}>
              <Alert
                heading='Det finns utskrivna ordrar som har ändrats'
                text='Dessa ordrar behöver skrivas ut på nytt.'
                linkText='Se ordrar som ändrats'
              />
            </div>
          )}
          {(selectedView === 2 && productionListsToReprint.length > 0) && (
            <div onClick={() => setUpdatedOrdersModal(true)}>
              <Alert
                heading='Det finns utskrivna produktionslistor där ordrar har ändrats'
                text='Dessa produktionslistor behöver skrivas ut på nytt.'
                linkText='Se produktionslistor som ändrats'
              />
            </div>
          )}
          {selectedView === 1 && storeId && (
            <SearchOrder
              onLineItemClick={onOpenModalFromSearch}
              onPrintOrder={printOrder}
              data={cateringOrders}
              userId={userId}
              storeId={storeId}
            />
          )}
          {cateringOrders.map((day, index) => {
            const { date, orders } = day
            return (
              <section className={styles.section} key={index}>
                {selectedView === 1 ? (
                  <>
                    <div className={styles.printAllWrapper}>
                      <h2 className={styles.sectionHeading}>
                        Leveransdatum: {weekdays[index]}, {date}
                      </h2>
                      <button
                        onClick={() => setOrdersToPrint(orders)}
                        className={styles.printButton}
                      >
                        <PrintIcon
                          color='#CF2E05'
                          className={styles.printIcon}
                        />
                        Skriv ut alla ordrar
                      </button>
                    </div>
                    <ul className={styles.list}>
                      {orders.map((order, i) => {
                        const { prevId, nextId } = getIds(index, i)
                        return (
                          storeId ?
                            <CateringOrderItem
                              onCancel={cancelOrder}
                              onPrintOrder={printOrder}
                              status='upcoming'
                              storeId={storeId}
                              modalIsActive={order.id === activeModal}
                              setActiveModal={setActiveModal}
                              prevId={prevId}
                              nextId={nextId}
                              key={order?.id}
                              item={order}
                              userId={userId}
                              singleModal={openedFromSearch}
                              resetSingleModal={setOpenedFromSearch}
                              productionListsToReprint={productionListsToReprint}
                              setProductionListsToReprint={setProductionListsToReprint}
                            /> : <span>Butik id och användar id saknas</span>
                        )
                      })}
                    </ul>
                    <div ref={printOrderRef}>
                      {ordersToPrint?.map((order) => {
                        return (
                          <PrintCateringOrder
                            order={order}
                            key={order.orderNo}
                          />
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <CateringProductionList
                      orders={orders}
                      date={date}
                      onCancel={cancelOrder}
                      storeId={storeId}
                      userId={userId}
                      onPrintOrder={printOrder}
                      updatedOrders={ordersToReprint}
                      setProductionListToReprint={setProductionListsToReprint}
                    />
                    <div ref={printListsRef}>
                      {listsToReprint.map((list, i) => {
                        const deliveryDate = list.date
                        return (
                          <PrintCateringProductionList
                            orders={list.cateringProducts}
                            date={deliveryDate}
                            key={i}
                          />
                        )
                      })}
                    </div>
                  </>
                )}
              </section>
            )
          })}
          <Modal
            active={updatedOrdersModal}
            onCancel={() => setUpdatedOrdersModal(false)}
            maxWidth='810px'
          >
            <div className={styles.content}>
              <div className={styles.modalContent}>
                <div className={styles.modalInfo}>
                  <button
                    className={styles.closeButton}
                    onClick={() => setUpdatedOrdersModal(false)}
                    type='button'
                  >
                    <CloseIcon className={styles.closeIcon} color='var(--redMid)' />
                  </button>
                  <h3 className={styles.modalHeading}>
                    {selectedView === 1 ? 'Ändrade ordrar' : 'Ändrade produktionslistor'}
                  </h3>
                </div>
                <div className={styles.bannerWrapper}>
                  <Banner theme="warning">
                    <div className={styles.bannerInfo}>
                      <span className={styles.bannerHeading}>
                        {selectedView === 1 ? `Skriv ut ${ordersToReprint.length > 1 ? 'nedan ordrar' : 'nedan order'} på nytt` 
                          : 'Skriv ut produktionslistor för nedan datum på nytt'}
                      </span>
                      <span className={styles.bannerText}>
                        {selectedView === 1 ? 'Nedan ordrar är utskrivna sedan tidigare men har blivit ändrade efter utskrift.' : 'Dessa datum innehåller ordar som har blivit ändrade.'}
                      </span>
                    </div>
                  </Banner>
                </div>
                {selectedView === 1 ? (
                  <div className={styles.updatedOrders}>
                    {ordersToReprint.map((orderToReprint, i) => {
                      return (
                        <div className={styles.updatedOrder} key={i}>
                          <CateringOrderLineItem
                            status='upcoming'
                            storeId={storeId as string}
                            key={orderToReprint?.id}
                            item={orderToReprint}
                            userId={userId}
                            updatedOrderModal={true}
                          />
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div className={styles.updatedProductionLists}>
                    <div className={styles.updatedProductionList}>
                      <ul className={styles.updatedProductionListItems}>
                        {productionListsByDate.map((list, i) => {
                          return (
                            <div key={i} className={styles.updatedProductionListItem}>
                              <h3 className={styles.updatedProductionListHeading}>Leveransdatum: {list.date}</h3>
                              <span>{list.orderNo && list.orderNo.length > 1 ? 'Berörda ordrar:' : 'Berörd order:'}</span>
                              {(list.orderNo as string[]).map((order: string, i: number) => (
                                <li key={i} className={styles.updatedListOrderNo}>
                                  {order}
                                </li>
                              ))}
                            </div>
                          )})}
                      </ul>
                    </div>
                  </div>
                )}
                <div className={styles.printWrapper}>
                  <TextButton
                    onClick={() => { selectedView === 1 ? setOrdersToPrint(ordersToReprint) : reprintProductionLists() }}
                    text={selectedView === 1 ? `Skriv ut ${ordersToReprint.length > 1 ? 'uppdaterade ordrar' : 'uppdaterad order '}`
                      : `Skriv ut  ${productionListsToReprint.length > 1 ? 'uppdaterade produktionslistor' : 'uppdaterad produktionslista '}`}
                    style={{
                      padding: '1.5rem',
                    }}
                  >
                    <span className={styles.iconWrapper}>
                      <PrintIcon color='#cf2e05' className={styles.printIcon} />
                    </span>
                  </TextButton>
                </div>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <div className={styles.noOrders}>
          <p>Just nu finns det inga kommande ordrar.</p>
        </div>
      )}
      <CateringMenu
        isOpen={menuIsOpen}
        updateStatus={setMenuIsOpen}
        channelTypes={selectedStore && selectedStore.channelTypes}
      />
      <button
        onClick={() => setMenuIsOpen((prev) => !prev)}
        className={menuIsOpen ? styles.activeMenuButton : styles.menuButton}
        type='button'
      >
        <span className={styles.menuButtonText}>
          {selectedStore && selectedStore?.name ? (
            <>{selectedStore.name}</>
          ) : (
            <>Meny</>
          )}
        </span>
        <div className={styles.menuIcons}>
          <span className={styles.menuIcon}>
            <MarketPlaceIcon color='var(--red)' className={styles.icon} />
          </span>
          <span className={styles.activeMenuIcon}>
            <CloseIcon color='currentColor' className={styles.icon} />
          </span>
        </div>
      </button>
    </div>
  )
}

function mapState (state: RootState) {
  return {
    selectedStore: state.store.selectedStore,
    cateringOrders: state.order.cateringOrders,
    status: state.order.status,
    userId: state.user.current?.id
  }
}
function mapDispatch (dispatch: Dispatch) {
  return {
    fetchOrders: dispatch.order.fetchCateringOrders,
    cancelOrder: dispatch.order.cancelCateringOrders,
    printOrder: dispatch.order.printCateringOrder,
    fetchUserStores: dispatch.store.fetchUserStores,
  }
}

export default connect(mapState, mapDispatch)(Catering)