import React, { ReactNode } from 'react'
import styles from './Banner.module.css'
import InfoIcon from '../icons/Info'
import CheckmarkIcon from '../icons/Check'
import WarningIcon from '../icons/Warning'

interface Props {
  children?: ReactNode
  theme: string
}

function Banner({
  children,
  theme
}: Props) {

  const getIcon = (theme: string) => {
    switch (theme) {
    case 'info':
      return <InfoIcon className={styles.icon} color='currentColor'/>
    case 'success':
      return <CheckmarkIcon className={styles.icon} color='currentColor'/>
    case 'warning':
      return <WarningIcon className={styles.icon} color='currentColor'/>
    default:
      return 
    }
  }

  const getTheme = (theme: string) => {
    switch (theme) {
    case 'info':
      return {
        backgroundColor: 'var(--lightPurple)',
        color: 'var(--darkPurple)',
        boxShadow: '0px 0px 1px 0px rgba(101, 32, 130, 0.40)'
      }
    case 'success':
      return {
        backgroundColor: '#F5FAF4',
        color: '#2F6147',
        boxShadow: '0px 0px 1px 0px rgba(47, 97, 71, 0.3)',
        margin: '0.25rem 0 0.5rem'
      }
    case 'warning':
      return {
        backgroundColor: '#FEF7F3',
        color: 'var(--redMid)',
        boxShadow: '0px 0px 1px 0px rgba(227, 0, 11, 0.40)'
      }
    default:
      return 
    }
  }
  
  return (
    <div className={styles.bannerContent} style={getTheme(theme)}>
      {getIcon(theme)}
      {children}
    </div>
  )
}

export default Banner