import { OrderItem } from "../types/Order"

export interface OrderDifference {
 ids: {
    id: string;
    quantity: number;
 }[];
 newQuantity: number;
}

const isAmountUpdated = (
  propertyNames: (keyof OrderItem)[],
  editedOrderItems: OrderItem[],
  originalOrderItems: OrderItem[]
): [boolean, OrderDifference[]] => {
  if (!editedOrderItems || !originalOrderItems) {
    return [false, []]
  }

  const orderUpdates: OrderDifference[] = editedOrderItems.reduce((prev: OrderDifference[] , editedItem, index) => {
    const originalItem = originalOrderItems[index]
    let itemHasDifference = false

    for (const propertyName of propertyNames) {
      if (editedItem[propertyName] !== originalItem[propertyName]) {
        itemHasDifference = true
        break
      }
    }

    if (itemHasDifference) {
      prev.push({ ids: editedItem.ids, newQuantity: editedItem.amount })
    }

    return prev
  }, [])

  return [orderUpdates.length > 0, orderUpdates]
}

export default isAmountUpdated