import { OpenHour } from "../types/Store"

export const getHourlySlots = (dayNumber: number, openHours: OpenHour[]) => {
  const dayObject = openHours.find(day => day.day === dayNumber)
  
  if (!dayObject) return []

  const { open, close } = dayObject

  const timeToDate = (timeString: string) => {
    const [ hours, minutes ] = timeString.split(':')
    const date = new Date()
    date.setHours(+hours, +minutes, 0, 0)
    return date
  }

  // Convert open and close times to Date objects
  let openTime = timeToDate(open)
  const closingTime = timeToDate(close)

  // Adjust closing time if it's "00:00" to represent the end of the day
  if (close === "00:00") {
    closingTime.setDate(closingTime.getDate() + 1)
  }

  const timeSlots = []

  while (openTime < closingTime) {
    const nextHour = new Date(openTime.getTime() + 60 * 60 * 1000) // Add 1 hour
     
    // Format the times as "HH:MM"
    const currentSlot = `${openTime.toTimeString().slice(0, 5)} - ${nextHour.toTimeString().slice(0, 5)}`
    timeSlots.push(currentSlot)
     
    openTime = nextHour // Move to the next hour
  }

  return timeSlots
}