/* eslint-disable indent */
import { createModel } from '@rematch/core'
import { format, addDays } from 'date-fns'
import { FETCHING, IDLE } from '../helpers/constants'
import { getItemsCountFrom } from '../helpers/getItemsCount'
import { groupByStatus } from '../helpers/groupOrders'
import { fetchFrom } from '../utils/fetchData'
import { emptyGuid } from '../constants'
import { sendServiceData } from '../utils/httpClient'
import type { RootModel } from '.'
import { Status } from '../types/Status'
import { AnonymousOrder, CateringOrder, Order, OrderHistory, OrderItem, OrderStatusUpdate, OrderUpdateOperation, UpdatedOrder } from '../types/Order'
import logError from '../utils/logging'
import groupCateringOrderItems from '../helpers/groupCateringOrderItems'
import { getOrderHistory } from '../helpers/getOrderHistory'

interface OrderState {
  status: Status,
  allOrders: Order[],
  ordersWaiting: Order[],
  ordersBeingPrepared: Order[],
  ordersReadyForDelivery: Order[],
  ordersPickedUp: Order[],
  orderHistory: OrderHistory[],
  cateringOrders: CateringOrder[],
  cateringHistory: CateringOrder[],
  cateringReport: CateringOrder[],
}

export const order = createModel<RootModel>()({
  state: {
    status: IDLE,
    allOrders: [],
    ordersWaiting: [],
    ordersBeingPrepared: [],
    ordersReadyForDelivery: [],
    ordersPickedUp: [],
    orderHistory: [],
    cateringOrders: [],
    cateringHistory: [],
    cateringReport: [],
  } as OrderState,
  reducers: {
    startFetching (state) {
      return { ...state, status: FETCHING }
    },
    setOrdersWaiting (state, payload: Order[]) {
      return { ...state, status: IDLE, ordersWaiting: payload }
    },
    setAllOrders (state, payload: Order[]) {
      return { ...state, status: IDLE, allOrders: payload }
    },
    setOrdersBeingPrepared (state, payload: Order[]) {
      return { ...state, status: IDLE, ordersBeingPrepared: payload }
    },
    setOrdersReadyForDelivery (state, payload: Order[]) {
      return { ...state, status: IDLE, ordersReadyForDelivery: payload }
    },
    setOrdersPickedUp (state, payload: Order[]) {
      return { ...state, status: IDLE, ordersPickedUp: payload }
    },
    setOrderHistory (state, payload: OrderHistory[]) {
      return { ...state, status: IDLE, orderHistory: payload }
    },
    setCateringOrders (state, payload: CateringOrder[]) {
      return { ...state, status: IDLE, cateringOrders: payload }
    },
    setCateringHistory (state, payload: CateringOrder[]) {
      return { ...state, status: IDLE, cateringHistory: payload }
    },
    setCateringReport (state, payload: CateringOrder[]) {
      return { ...state, status: IDLE, cateringReport: payload }
    },
  },
  effects: (dispatch) => ({
    clearOrders () {
      dispatch.order.setAllOrders([])
      dispatch.order.setOrdersWaiting([])
      dispatch.order.setOrdersBeingPrepared([])
      dispatch.order.setOrdersReadyForDelivery([])
      dispatch.order.setOrdersPickedUp([])
    },
    async fetchOrders (payload) {
      const { storeId, fetching } = payload
      if (fetching === true) {
        dispatch.order.startFetching()
      }

      try {
        const storeOrders = await fetchFrom<Order[]>(`/api/stores/${storeId}/orders`)
        if (storeOrders && storeOrders.length > 0) {
          const storeOrdersWithItemCount = getItemsCountFrom(storeOrders)
          dispatch.order.setAllOrders(storeOrdersWithItemCount)
          const { waiting = [], beingPrepared = [], readyForDelivery = [], pickedUp = [] } = groupByStatus<Order>(storeOrdersWithItemCount)

          dispatch.order.setOrdersWaiting(waiting)
          dispatch.order.setOrdersBeingPrepared(beingPrepared)
          dispatch.order.setOrdersReadyForDelivery(readyForDelivery)
          dispatch.order.setOrdersPickedUp(pickedUp)
        } else {
          dispatch.order.clearOrders()
        }
      } catch (error) {
        logError(error as Error)
        dispatch.order.clearOrders()
      }
    },
    async fetchOrderById (payload, state) {
      const { storeId, orderId, loading } = payload

      if (loading === true) {
        dispatch.order.startFetching()
      }

      try {
        const newOrder = await fetchFrom<Order>(`/api/stores/${storeId}/orders/${orderId}`)
        if (newOrder) {
          const newOrderStatus = newOrder?.status || 0
          const countItemsOnNewOrder = getItemsCountFrom([newOrder])
          if (newOrderStatus >= 0 || newOrderStatus < 4) {
            if (state.order.allOrders) {
              dispatch.order.setAllOrders([...state.order.allOrders, ...countItemsOnNewOrder])
            } else {
              dispatch.order.setAllOrders([...countItemsOnNewOrder])
            }

            switch (newOrderStatus) {
              case 0:
                dispatch.order.setOrdersWaiting([...state.order.ordersWaiting, ...countItemsOnNewOrder])
                break
              case 1:
                dispatch.order.setOrdersBeingPrepared([...state.order.ordersBeingPrepared, ...countItemsOnNewOrder])
                break
              case 2:
                dispatch.order.setOrdersReadyForDelivery([...state.order.ordersReadyForDelivery, ...countItemsOnNewOrder])
                break
              case 3:
                dispatch.order.setOrdersPickedUp([...state.order.ordersPickedUp, ...countItemsOnNewOrder])
                break
              default:
                break
            }
          }
        }
      } catch (error) {
        logError(error as Error)
      }
    },
    async fetchOrderHistory (payload: { storeId: string, dateFrom: string, dateTo: string }) {
      const { storeId, dateFrom, dateTo } = payload

      dispatch.order.startFetching()

      try {
        const storeOrderHistory = await fetchFrom<OrderHistory[]>(`/api/stores/${storeId}/orders/delivered`, {
          method: 'POST',
          body: JSON.stringify({
            startDate: new Date(dateFrom).toISOString(),
            endDate: new Date(dateTo).toISOString(),
          }),
        })
        if (storeOrderHistory) {
          const sortedHistory = storeOrderHistory.sort((a, b) => {
            return new Date(b.date).getTime() - new Date(a.date).getTime()
          })
          dispatch.order.setOrderHistory(sortedHistory)
        } else {
          throw new Error()
        }
      } catch (error) {
        logError(error as Error)
        dispatch.order.setOrderHistory([])
      }
    },
    async fetchCateringHistory (payload) {
      const { storeId, dateFrom, dateTo } = payload
      dispatch.order.startFetching()
      try {
        const data = await fetchFrom<Order[]>(`/api/stores/${storeId}/orders/scheduled/history/${dateFrom}/${dateTo}`)
        if (data) {
          const grouped = data.reduce((prev, current) => {
            const currentDate = format(new Date(current.deliveryDate), 'yyyy-MM-dd')
            if (prev[currentDate]) {
              return {
                ...prev,
                [currentDate]: {
                  date: currentDate,
                  orders: [...prev[currentDate].orders, current],
                },
              }
            } else {
              return {
                ...prev,
                [currentDate]: {
                  date: currentDate,
                  orders: [current],
                },
              }
            }
          }, {} as { [key: string]: { date: string, orders: Order[] } })

          const sortedHistory = Object.keys(grouped)
            .map((i) => ({
              ...grouped[i],
              orders: grouped[i]?.orders.map((cateringOrder) => {
                const itemsGroups = cateringOrder.orderItemsCatering.reduce((prev, current) => {
                  if (current.variantId !== emptyGuid && current.variantId + current.message in prev) {
                    return {
                      ...prev,
                      [current.variantId + current.message]: {
                        ...current,
                        amount: prev[current.variantId + current.message].amount + current.quantity,
                        ids: [...prev[current.variantId + current.message].ids, { id: current.id, quantity: current.quantity }]
                      },
                    }
                  } else if (
                    (current.variantId === emptyGuid || current.variantId === null) &&
                    current.name + current.message in prev
                  ) {
                    return {
                      ...prev,
                      [current.name + current.message]: {
                        ...current,
                        amount: prev[current.name + current.message]?.amount + current.quantity,
                        ids: [...(prev[current.name + current.message]?.ids || []), { id: current.id, quantity: current.quantity }]
                      },
                    }
                  }
                  return {
                    ...prev,
                    [current.variantId !== emptyGuid
                      ? current.variantId + current.message
                      : current.name + current.message]: {
                      ...current,
                      amount: current.quantity,
                      ids: [{ id: current.id, quantity: current.quantity }],
                    },
                  }
                }, {} as { [key: string]: OrderItem })
                const groupedItems = Object.keys(itemsGroups).map((i) => itemsGroups[i])
                return {
                  ...cateringOrder,
                  orderItemsCatering: groupedItems,
                }
              }),
            }))
            .sort((a, b) => {
              return new Date(b.date).getTime() - new Date(a.date).getTime()
            })

          const sortedOrdersWithHistory = sortedHistory.map((date) => {
            return {
              ...date,
              orders: date.orders.map(order => {
                return getOrderHistory(order)
              })
            }
          })
          dispatch.order.setCateringHistory(sortedOrdersWithHistory)
        } else {
          throw new Error("No data from fetchCateringHistory")
        }
      } catch (error) {
        logError(error as Error)
        dispatch.order.setCateringHistory([])
      }
    },
    async fetchCateringReport (payload) {
      const { storeId, dateFrom, dateTo } = payload
      dispatch.order.startFetching()
      try {
        const data = await fetchFrom<Order[]>(`/api/stores/${storeId}/orders/scheduled/${dateFrom}/${dateTo}`, {
          method: 'GET',
        })
        if (data) {
          const grouped = data.reduce((prev, current) => {
            const currentDate = format(new Date(current.deliveryDate), 'yyyy-MM-dd')
            if (prev[currentDate]) {
              return {
                ...prev,
                [currentDate]: {
                  date: currentDate,
                  orders: [...prev[currentDate].orders, current],
                },
              }
            } else {
              return {
                ...prev,
                [currentDate]: {
                  date: currentDate,
                  orders: [current],
                },
              }
            }
          }, {} as { [key: string]: { date: string, orders: Order[] } })

          const sortedReport = Object.keys(grouped)
            .map((i) => ({
              ...grouped[i],
              orders: grouped[i]?.orders.map((cateringOrder) => {
                const itemsGroups = cateringOrder.orderItemsCatering.reduce((prev, current) => {
                  if (current.variantId !== emptyGuid && current.variantId + current.message in prev) {
                    return {
                      ...prev,
                      [current.variantId + current.message]: {
                        ...current,
                        amount: prev[current.variantId + current.message].amount + current.quantity,
                        ids: [...prev[current.variantId + current.message].ids, { id: current.id, quantity: current.quantity }]
                      },
                    }
                  } else if (
                    (current.variantId === emptyGuid || current.variantId === null) &&
                    current.name + current.message in prev
                  ) {
                    return {
                      ...prev,
                      [current.name + current.message]: {
                        ...current,
                        amount: prev[current.name + current.message]?.amount + current.quantity,
                        ids: [...(prev[current.name + current.message]?.ids || []), { id: current.id, quantity: current.quantity }]
                      },
                    }
                  }
                  return {
                    ...prev,
                    [current.variantId !== emptyGuid
                      ? current.variantId + current.message
                      : current.name + current.message]: {
                      ...current,
                      amount: current.quantity,
                      ids: [{ id: current.id, quantity: current.quantity }],
                    },
                  }
                }, {} as { [key: string]: OrderItem })
                const groupedItems = Object.keys(itemsGroups).map((i) => itemsGroups[i])
                return {
                  ...cateringOrder,
                  orderItemsCatering: groupedItems,
                }
              }),
            }))
            .sort((a, b) => {
              return new Date(a.date).getTime() - new Date(b.date).getTime()
            })

          const sortedReportWithHistory = sortedReport.map((date) => {
            return {
              ...date,
              orders: date.orders.map(order => {
                return getOrderHistory(order)
              })
            }
          })
          dispatch.order.setCateringReport(sortedReportWithHistory)
        } else {
          throw new Error("No data from fetchCateringReport")
        }
      } catch (error) {
        logError(error as Error)
        dispatch.order.setCateringReport([])
      }
    },
    async fetchCateringOrders (payload) {
      const { storeId } = payload
      dispatch.order.startFetching()

      try {
        const cateringOrders = await fetchFrom<Order[]>(`/api/stores/${storeId}/orders/scheduled`, {
          method: 'GET',
        })
        if (cateringOrders) {
          // TODO: productType needed on every item in orderItemsCatering to distinguish between an ordinary product and a buffet product
          const cateringOrdersNoCancelledOrders = cateringOrders.filter((order) => order.status === 0)

          const grouped = cateringOrdersNoCancelledOrders.reduce((prev, current) => {
            const currentDate = format(new Date(current.deliveryDate), 'yyyy-MM-dd')
            if (prev[currentDate]) {
              return {
                ...prev,
                [currentDate]: {
                  date: currentDate,
                  orders: [...prev[currentDate].orders, current],
                },
              }
            } else {
              return {
                ...prev,
                [currentDate]: {
                  date: currentDate,
                  orders: [current],
                },
              }
            }
          }, {} as { [key: string]: { date: string, orders: Order[] } })

          const sortedOrders = Object.keys(grouped)
            .map((i) => ({
              ...grouped[i],
              orders: grouped[i]?.orders.map((cateringOrder) => {
                // const itemsGroups = handleGroupCateringOrderItems(cateringOrder.orderItemsCatering)
                const itemsGroups = cateringOrder.orderItemsCatering.reduce((prev, current) => {
                  if (current.variantId !== emptyGuid && current.variantId + current.message in prev) {
                    return {
                      ...prev,
                      [current.variantId + current.message]: {
                        ...current,
                        amount: prev[current.variantId + current.message].amount + current.quantity,
                        ids: [...prev[current.variantId + current.message].ids, { id: current.id, quantity: current.quantity }]
                      },
                    }
                  } else if (
                    (current.variantId === emptyGuid || current.variantId === null) &&
                    current.name + current.message in prev
                  ) {
                    return {
                      ...prev,
                      [current.name + current.message]: {
                        ...current,
                        amount: prev[current.name + current.message]?.amount + current.quantity,
                        ids: [...(prev[current.name + current.message]?.ids || []), { id: current.id, quantity: current.quantity }]
                      },
                    }
                  }
                  return {
                    ...prev,
                    [current.variantId !== emptyGuid
                      ? current.variantId + current.message
                      : current.name + current.message]: {
                      ...current,
                      amount: current.quantity,
                      ids: [{ id: current.id, quantity: current.quantity }],
                    },
                  }
                }, {} as { [key: string]: OrderItem })
                const groupedItems = Object.keys(itemsGroups).map((i) => itemsGroups[i])
                return {
                  ...cateringOrder,
                  orderItemsCatering: groupedItems
                }
              })
                .sort((a, b) => a.deliveryTimeSlot.localeCompare(b.deliveryTimeSlot))
            }))
            .sort((a, b) => {
              return new Date(a.date).getTime() - new Date(b.date).getTime()
            })

          const sortedOrdersWithHistory = sortedOrders.map((date) => {
            return {
              ...date,
              orders: date.orders.map(order => {
                return getOrderHistory(order)
              })
            }
          })

          dispatch.order.setCateringOrders(sortedOrdersWithHistory)
        } else {
          throw new Error("No data from fetchCateringOrders")
        }
      } catch (error) {
        logError(error as Error)
        dispatch.order.setCateringOrders([])
      }
    },
    async cancelCateringOrders (payload: { storeId: string, orderId: string }) {
      const { storeId, orderId } = payload
      try {
        const cancelled = await fetchFrom(`/api/orders/${orderId}/${storeId}/cancel`, {
          method: 'GET',
        })
        dispatch.order.fetchCateringOrders({ storeId })
        console.log(cancelled)
      } catch (error) {
        logError(error as Error)
      }
    },
    async printCateringOrder(payload, state) {
      const { storeId, orderIds, userId, type, isSingleOrder = true } = payload 
      try {
         const data = isSingleOrder ? { orderIds: [orderIds], storeId, userId, type } : { orderIds, storeId, userId, type }
         const res = await sendServiceData('orderApi', 'v1', '/order/setordersasprintedbytype', data, 'PUT')
     
         if (res) {
           const cateringOrders = state.order.cateringOrders || []
           const orders = cateringOrders.map((order) => {
             return {
               ...order,
               orders: order.orders.map((o) => {
                 if (isSingleOrder && o.id === res.data[0].id) {
                   return {
                     ...o,
                     printedByUsersWithType: [...res.data[0].printedByUsersWithType]
                   }
                 } else if (!isSingleOrder && orderIds.indexOf(o.id) > -1) {
                  const updatedOrderData = res.data.find((order: Order) => order.id === o.id)
                  if (updatedOrderData) {
                    return {
                      ...o,
                      printedByUsersWithType: [...updatedOrderData.printedByUsersWithType]
                    }
                  }
                 }
                 return o
               }),
             }
           })
           dispatch.order.setCateringOrders(orders)
         }
      } catch (error) {
         logError(error as Error)
      }
     },     
    async updateStatusAsync (payload, state) {
      const { storeId, orderId, status } = payload || {}

      try {
        const res = await fetchFrom<OrderStatusUpdate>(`/api/stores/${storeId}/orders/${orderId}/${status}/V2`, {
          method: 'PUT',
        })

        if (res) {
          const storeOrders = state.order.allOrders || []
          const orders = storeOrders.map((order) => {
            if ((order as Order).id === res.id) { //id is undefined if anonymous thus false
              return {
                ...order,
                status: res.status,
              }
            }
            return order
          })
          dispatch.order.setAllOrders(orders)

          const { waiting = [], beingPrepared = [], readyForDelivery = [], pickedUp = [] } = groupByStatus<Order>(orders)
          dispatch.order.setOrdersWaiting(waiting)
          dispatch.order.setOrdersBeingPrepared(beingPrepared)
          dispatch.order.setOrdersReadyForDelivery(readyForDelivery)
          dispatch.order.setOrdersPickedUp(pickedUp)

        }
      } catch (error) {
        logError(error as Error)
      }
    },
    updateStatusState (payload, state) {
      const storeOrders = state.order.allOrders || []
      const { existingOrder, orderStatus } = payload

      const orders = storeOrders.map((order) => {
        if ((order as Order).id === existingOrder.id) { //id is undefined if anonymous thus false
          return {
            ...order,
            status: orderStatus,
          }
        }
        return order
      })
      dispatch.order.setAllOrders(orders)
      const { waiting = [], beingPrepared = [], readyForDelivery = [], pickedUp = [] } = groupByStatus<Order>(orders)
      dispatch.order.setOrdersWaiting(waiting)
      dispatch.order.setOrdersBeingPrepared(beingPrepared)
      dispatch.order.setOrdersReadyForDelivery(readyForDelivery)
      dispatch.order.setOrdersPickedUp(pickedUp)
    },
    async updateItemStatusAsync (payload, state) {
      const { storeId, orderId, itemId, itemStatus } = payload
      const storeOrders = state.order.allOrders || []

      try {
        const res = await fetchFrom<OrderStatusUpdate>(`/api/stores/${storeId}/orders/${orderId}/orderitems/${itemId}/${itemStatus}/V2`, {
          method: 'PUT',
        })


        const orders = storeOrders.map((order) => {
          if ((order as Order).id === res.id) { //id is undefined if anonymous thus false
            return {
              ...order,
              orderItemsGrocery: (order as Order).orderItemsGrocery.map((groceryItem) => {
                if (groceryItem.id === res.itemId && groceryItem.status !== res.status) {
                  return {
                    ...groceryItem,
                    status: res.status,
                  }
                }
                return groceryItem
              }),
            }
          }
          return order
        })
        dispatch.order.setAllOrders(orders)
        const { waiting = [], beingPrepared = [], readyForDelivery = [], pickedUp = [] } = groupByStatus<Order>(orders)
        dispatch.order.setOrdersWaiting(waiting)
        dispatch.order.setOrdersBeingPrepared(beingPrepared)
        dispatch.order.setOrdersReadyForDelivery(readyForDelivery)
        dispatch.order.setOrdersPickedUp(pickedUp)
      } catch (error) {
        logError(error as Error)
      }
    },
    updateItemStatus (payload, state) {
      const { orderId, itemId, itemStatus } = payload
      const storeOrders = state.order.allOrders || []

      const orders = storeOrders.map((order) => {
        if ((order as Order).id === orderId) { //id is undefined if anonymous thus false
          return {
            ...order,
            orderItemsGrocery: (order as Order).orderItemsGrocery.map((groceryItem) => {
              if (groceryItem.id === itemId && groceryItem.status !== itemStatus) {
                return {
                  ...groceryItem,
                  status: itemStatus,
                }
              }
              return groceryItem
            }),
          }
        }
        return order
      })
      dispatch.order.setAllOrders(orders)
      const { waiting = [], beingPrepared = [], readyForDelivery = [], pickedUp = [] } = groupByStatus<Order>(orders)
      dispatch.order.setOrdersWaiting(waiting)
      dispatch.order.setOrdersBeingPrepared(beingPrepared)
      dispatch.order.setOrdersReadyForDelivery(readyForDelivery)
      dispatch.order.setOrdersPickedUp(pickedUp)
    },
    async editCateringOrder(payload: { orderId: string, storeId: string, userName: string, operations: OrderUpdateOperation[] }, state) {
      const { orderId, storeId, userName, operations } = payload
    
      try {
        const res = await fetchFrom<UpdatedOrder>(`/api/stores/${storeId}/orders/update`, {
          method: 'POST',
          body: JSON.stringify({
            userName: userName,
            orderId: orderId,
            storeId: storeId,
            operations: operations
          }),
        })
    
        if (res.updatedOrder) {
          const updatedOrderDate = format(new Date(res.updatedOrder.deliveryDate), 'yyyy-MM-dd')
          const orderItemsCatering = groupCateringOrderItems(res.updatedOrder.orderItemsCatering)
          const updatedOrder = {
            ...res.updatedOrder,
            orderItemsCatering: orderItemsCatering,
            deliveryDate: updatedOrderDate,
            deliveryTimeSlot: res.updatedOrder.deliveryTimeSlot,
            orderHistory: res.updatedOrder.orderHistory,
            storeComment: res.updatedOrder.storeComment,
            totalPrice: res.updatedOrder.totalPrice
          }
    
          let dateExists = false
    
          // Remove the order from the old date
          state.order.cateringOrders = state.order.cateringOrders.map(cateringOrder => {
            cateringOrder.orders = cateringOrder.orders.filter(order => order.id !== orderId)
            return cateringOrder
          }).filter(cateringOrder => cateringOrder.orders.length > 0) // Remove empty dates
    
          // Add the order to the new date
          state.order.cateringOrders = state.order.cateringOrders.map(cateringOrder => {
            if (cateringOrder.date === updatedOrderDate) {
              dateExists = true
              cateringOrder.orders.push(getOrderHistory(updatedOrder))
              cateringOrder.orders.sort((a, b) => a.deliveryTimeSlot.localeCompare(b.deliveryTimeSlot))
            }
            return cateringOrder
          })
    
          if (!dateExists) {
            state.order.cateringOrders.push({
              date: updatedOrderDate,
              orders: [getOrderHistory(updatedOrder)]
            })
          }
    
          state.order.cateringOrders.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    
          dispatch.order.setCateringOrders([...state.order.cateringOrders])
          return true
        }
      } catch (error) {
        logError(error as Error)
        return false
      }
    },
  }),
})
