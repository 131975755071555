/* eslint-disable indent */
import React from 'react'
import { format } from 'date-fns'
import { getDayOfWeek } from '../helpers/getWeekday'
import OrderNumber from './OrderNumber'
import Modal from './Modal'
import CateringItemDetail from './CateringItemDetail'
import TextButton from './TextButton'
import PrintIcon from '../icons/Print'
import PrintCateringOrder from './PrintCateringOrder'
import AngleIcon from '../icons/Angle'
import CloseIcon from '../icons/Close'
import CopyIcon from '../icons/Copy'
import CheckIcon from '../icons/Check'
import TrashIcon from '../icons/Trash'
import EditIcon from '../icons/Edit'
import { getPaymentMethod } from '../helpers/getPaymentMethod.js'
import { getFormattedPrice } from '../helpers/formatPrice'
import Contact from './Contact'
import styles from './CateringOrderItem.module.css'
import type { OrderUpdateError, Order, OrderItem, OrderUpdateOperation, CateringOrder, CateringOrderItem, PrintedByUserWithType } from '../types/Order'
import logError from '../utils/logging'
import Checkbox from './Checkbox'
import Banner from './Banner'
import isAmountUpdated from '../helpers/isAmountUpdated'
import createEditOrderOperations from '../helpers/createEditOrderOperations'
import OrderChange from './OrderChange'
import { Dispatch, RootState } from '../models'
import { connect } from 'react-redux'
import getReprintStatusOrders from '../helpers/getReprintStatusOrders'
import { ProductionListToReprint } from '../helpers/getReprintStatusProductionLists'
import PrintCateringProductionList from './PrintCateringProductionList'
import getUserFromLocalStorage from '../utils/getUserFromLocalStorage'
import { groupProductionList } from '../helpers/groupProductionLists'
import { OpenHour, Store } from '../types/Store'
import UpdateDelivery from './UpdateDelivery'

interface Props {
  item: Order
  userId?: string
  modalIsActive: boolean
  setActiveModal: (id: string | null) => void
  prevId: string | null
  nextId: string | null
  status?: string | number
  storeId: string
  onCancel?: (payload: { storeId: string, orderId: string }) => void
  onPrintOrder: (payload: { storeId: string, orderIds: string | string[], userId?: string, type: string, isSingleOrder: boolean }) => void
  singleModal?: boolean
  resetSingleModal?: React.Dispatch<React.SetStateAction<boolean>>
  editCateringOrder: (payload: { storeId: string, orderId: string, userName: string, operations: OrderUpdateOperation[] }) => Promise<boolean>
  productionListsToReprint?: ProductionListToReprint[] 
  cateringOrders: CateringOrder[]
  selectedStore: Store
}
interface Checkbox {
  id: string
  label: string
  checked: boolean
  isActive: boolean
}

function CateringOrderItem({
  item,
  userId,
  modalIsActive,
  setActiveModal,
  prevId,
  nextId,
  status,
  storeId,
  onCancel,
  onPrintOrder,
  singleModal = false,
  resetSingleModal = () => undefined,
  editCateringOrder,
  productionListsToReprint,
  cateringOrders,
  selectedStore
} : Props) {
  const {
    id,
    deliveryDisplayName,
    deliveryBongDisplayName,
    orderItemsCatering,
    userName,
    phoneNumber,
    email,
    street,
    postNumber,
    city,
    deliveryTimeSlot,
    deliveryFee,
    totalDiscount,
    totalPrice,
    restaurantMessage,
    paymentStatus,
    paymentProviderType,
    orderNo,
    printedByUser,
    orderHistory,
    printedByUsersWithType,
    originalTotalPrice
  } = item || {}

  // Store IDs for stores that are testing Edit order feature
  const storeIds = [
    // Testbutik
    '1214de17-a004-44f0-19ef-08d6538111e5',
    // Maxi Häggvik
    '55d57a2c-c673-4829-2a2f-08db46362f07',
    // MAXI ICA Stormarknad Flygstaden
    'b37f5b71-6526-4a3e-ebc7-08dab28d390e',
    // ICA Kvantum Klockaretorpet
    'ed8febc8-e568-4190-d58c-08dc135dd54d',
    // ICA Kvantum Kronoparken
    '89e8f63c-3add-4c7e-9c54-08db770c84ac',
    // ICA Kvantum Skövde
    'f2e2ebf6-9ab4-49af-a50d-08db13522e88',
    // ICA Maxi Alingsås
    '35512296-91fa-4450-0baa-08db0f57590e',
    // ICA Focus
    'f240eede-d2a5-4f59-d607-08d6d2dee46b',
    // ICA Maxi Kungälv
    '7cdba16e-4289-47f6-5dbc-08da3a6df97b',
    // ICA Maxi Linköping
    '9bf53a41-a67a-4ee8-a50c-08db13522e88',
  ]

  // Remove when printedByUser[] property becomes obsolete
  const printedOrder = React.useMemo(() => {
    if (printedByUser.includes(userId || '')) {
      return true
    } else {
      return false
    }
  }, [printedByUser.length])
  //

  const printedCateringOrder = () => {
    const printed = printedByUsersWithType?.find(print => 
      print.actions?.some(action => action.type === "IncomingOrders" && print.userId === userId)
    )?.actions?.find(action => action.type === "IncomingOrders")

    if (printed) {
      return true
    } else {
      return false
    }
  }

  const today = new Date().toISOString().split('T', 1)[0]
  const orderDate = format(new Date(item.createdAt), 'yyyy-MM-dd')
  const deliveryDate = format(new Date(item.deliveryDate), 'yyyy-MM-dd')
  const orderNoColor =
    status === 5 ? '#ededed' : status === 'upcoming' ? '#fdc300' : '#94c11a'
  const printOrderRef = React.useRef<HTMLDivElement>(null)
  const printListRef = React.useRef<HTMLDivElement>(null)
  const [updatedDeliveryDate, setUpdatedDeliveryDate] = React.useState(new Date(item.deliveryDate))
  const [updatedTimeSlot, setUpdatedTimeSlot] = React.useState(item.deliveryTimeSlot)
  const [currentMonth, setCurrentMonth] = React.useState(updatedDeliveryDate)
  const isDeliveryDateUpdated = deliveryDate !== format(new Date(updatedDeliveryDate), 'yyyy-MM-dd')
  const isDeliveryTimeSlotUpdated = item.deliveryTimeSlot !== updatedTimeSlot
  const [openHours, setOpenHours] = React.useState<OpenHour[]>([])
  const [hideItems, setHideItems] = React.useState<string[]>([])
  const [updateDelivery, setUpdateDelivery] = React.useState(false)
  const [activeConfirmationModal, setActiveConfirmationModal] =
    React.useState(false)
  const [emailCopied, setEmailCopied] = React.useState(false)
  const [editMode, setEditMode] = React.useState(false)
  const [localComment, setLocalComment] = React.useState<string>(item.storeComment || '')
  const [error, setError] = React.useState<OrderUpdateError>({ errorText: '', isError: false })
  const [editedOrderItems, setEditedOrderItems] = React.useState<OrderItem[]>(item.orderItemsCatering)
  const [printReminderModal, setPrintReminderModal] = React.useState(false)
  const [saveChangesModal, setSaveChangesModal] = React.useState(false)
  const [showUpdatedOrder, setShowUpdatedOrder] = React.useState(true)
  const [errorIndex, setErrorIndex] = React.useState<number[]>([])
  const [checkboxes, setCheckboxes] = React.useState<Checkbox[]>([
    { id: "1", label: 'Skriv ut uppdaterad order', checked: false, isActive: false }, 
    { id: "2", label: 'Skriv ut uppdaterad produktionslista', checked: false, isActive: false }])
  const [amountIsUpdated, updatedAmounts] = isAmountUpdated(['amount'], editedOrderItems, item.orderItemsCatering)
  const orderLastChanged = orderHistory && orderHistory[0].date 
  const [orderNeedsReprinting, setOrderNeedsReprinting] = React.useState(false)
  const [productionListNeedsReprinting, setProductionListNeedsReprinting] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const loggedInUser = getUserFromLocalStorage()
  const [filteredProductionList, setFilteredProductionList] = React.useState<CateringOrderItem[]>()

  const filterOrdersByDate = (groupedProductionLists: OrderItem[][], specificDate: string) => {
    return groupedProductionLists.map((group) => {
      return group.filter(orderItem => {
        return orderItem.deliveryDate === specificDate
        })
      })
  }

  const dayOfWeek = React.useMemo(() => {
    return getDayOfWeek(deliveryDate)
  }, [deliveryDate])

  React.useEffect(() => {
    if (orderHistory?.length && userId) { 
      const reprintStatus = getReprintStatusOrders(printedByUser, printedByUsersWithType as PrintedByUserWithType[], userId, orderLastChanged as string) as boolean
      setOrderNeedsReprinting(reprintStatus)
      setEditedOrderItems(item.orderItemsCatering)
    }
   }, [orderLastChanged, item.printedByUsersWithType])

   React.useEffect(() => {
    if (item.storeComment) {
      setLocalComment(item.storeComment)
    }
   }, [item.storeComment])

   React.useEffect(() => { 
    if (selectedStore && selectedStore.openHours) {
      setOpenHours(selectedStore.openHours)
    }}, [selectedStore])

   React.useEffect(() => {
    const listNeedsReprinting = productionListsToReprint?.some(order => order.orderNo === item.orderNo)
    setProductionListNeedsReprinting(listNeedsReprinting as boolean)

    const groupedProductionLists = cateringOrders.reduce<CateringOrderItem[][]>((acc, day) => {
      const { orders } = day
      const groupedDayOrder = groupProductionList(orders)
      return [...acc, groupedDayOrder]
    }, [])
    const targetDate = item?.deliveryDate
    const filteredGroupedProductionLists = filterOrdersByDate(groupedProductionLists, targetDate as string)
    const filteredArrays = filteredGroupedProductionLists.filter((innerArray) => innerArray.length > 0)
    setFilteredProductionList(filteredArrays[0] as CateringOrderItem[])
   }, [productionListsToReprint?.length])

   const togglePrintCheckboxes = () => {
    setCheckboxes(checkboxes.map((checkbox, index) => {
      let isActive = checkbox.isActive
      if (index === 0 && orderNeedsReprinting) {
        isActive = true
      } else if (index === 1 && productionListNeedsReprinting) {
        isActive = true
      } else {
        isActive = false
      }
      return {
       ...checkbox,
        isActive: isActive
      }
    }))
  }

  const paymentInfo = React.useMemo(() => {
    let infoText = 'Reserverad'
    if (paymentStatus === 200) {
      infoText = 'Betald'
    } else if (paymentStatus === 300) {
      infoText = 'Misslyckad'
    } else if (paymentStatus === 400)  {
      infoText = 'Avbruten'
    } else if (paymentStatus !== 200 && deliveryDate === today) {
      infoText = 'Pågående'
    }
    return infoText
  }, [paymentStatus])

  const paymentMethod = React.useMemo(() => {
    return getPaymentMethod(paymentProviderType)
  }, [paymentProviderType])

  function getArticleText() {
    const numberOfItems = orderItemsCatering?.reduce((prev, current) => {
      return prev + current.amount
    }, 0)

    if (numberOfItems > 1 || numberOfItems === 0) {
      return `${numberOfItems} artiklar`
    }
    return `${numberOfItems} artikel`
  }

  function onOpenDetail(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const element = event.target as HTMLElement
    if (element.tagName !== 'svg' && element.tagName !== 'path') {
      setActiveModal(id)
    }
  }

  function onPrint() {
    try {
      if (document && printOrderRef !== null) {
        const printContent = printOrderRef.current?.innerHTML
        const frame = (document.getElementById('printframe') as HTMLIFrameElement).contentWindow
        if(frame && printContent){
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
        }
      }
    } catch (error) {
      logError(error as Error)
    }
  }

  function onPrintStatus() {
    onPrintOrder({
      storeId,
      orderIds: id,
      userId,
      type: 'IncomingOrders',
      isSingleOrder: true
    })
    onPrint()
  }

  function onCancelOrder() {
    onCancel && onCancel({
      storeId,
      orderId: id
    })
    setActiveConfirmationModal(false)
  }

  function onOpenConfimationModal() {
    setActiveConfirmationModal(true)
    setActiveModal(null)
  }

  function onDoNotCancel() {
    setActiveConfirmationModal(false)
    setActiveModal(id)
  }

  function onDoNotPrint() {
    setPrintReminderModal(false)
    setActiveModal(null)
    setEditMode(false)
    setShowUpdatedOrder(true)
    setUpdateDelivery(false)
  }

  const reprintProductionList = () => {
    try {
      if (document && printListRef !== null) {
        const printContent = printListRef.current?.innerHTML
        const frame = (document.getElementById('printframe') as HTMLIFrameElement).contentWindow
        if (frame !== null && printContent) {
          frame.document.open()
          frame.document.write(printContent)
          frame.document.close()
          frame.focus()
          frame.print()
        }
      }

      const idsToPrint = filteredProductionList?.reduce((acc: string[], list) => {
        const productOrdersIds = list.products.map(product =>
          product.orders.map(order => order.id) 
        ).flat() 
      
        return [...acc,...productOrdersIds]
      }, [] as string[])
     
      const uniqueIdsToPrint = [...new Set(idsToPrint)]

        onPrintOrder({
          storeId,
          orderIds: uniqueIdsToPrint,
          userId: userId,
          isSingleOrder: false,
          type: 'ProductionList'
        })
      
    } catch (error) {
      logError(error as Error)
    }
  }
   
  const handleSave = async () => {
    setIsSaving(true)
    setError({ errorText: '', isError: false })
    let operations: OrderUpdateOperation[] = []
    
    try {
      if (!amountIsUpdated && (!localComment.trim() || localComment === item.storeComment) && !isDeliveryDateUpdated && !isDeliveryTimeSlotUpdated) {
        setEditMode(false)
        setUpdateDelivery(false)
        } else {
        const total = editedOrderItems.reduce((sum, item) => sum + item.amount * item.price, 0) + deliveryFee
        if (total > originalTotalPrice && paymentProviderType === 'Payex') {
          setError({ errorType: 'Payment', errorText: `Kortbetalning - Det går inte att genomföra ändringar som ökar det reserverade beloppet på ${getFormattedPrice(originalTotalPrice)}`, isError: true })
        } else {
          if (updatedAmounts.length > 0 || item.storeComment === null || item.storeComment !== localComment || isDeliveryDateUpdated || isDeliveryTimeSlotUpdated) {
            const newDeliveryDate = format(new Date(updatedDeliveryDate), 'yyyy-MM-dd')
            operations = await createEditOrderOperations(updatedAmounts, localComment !== item.storeComment ? localComment : null, isDeliveryDateUpdated ? newDeliveryDate : null, isDeliveryTimeSlotUpdated ? updatedTimeSlot : null)

            if (operations.length > 0) {
                const res = await editCateringOrder({
                userName: loggedInUser?.username as string,
                storeId,
                orderId: id,
                operations
              })
              if (res) {
                setShowUpdatedOrder(true)
                setEditMode(false)
                setUpdateDelivery(false)
              } else {
                setError({ errorType: 'General', errorText: 'Ändringarna kunde inte sparas', isError: true })
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
      setError({ errorType: 'General', errorText: 'Ett fel inträffade', isError: true })
    } finally {
      setIsSaving(false)
    }
  }

const handleAmountChange = (index: number, newAmount: number, id: string, hideItem?: boolean) => {
  setError({ errorText: '', isError: false })
  setErrorIndex([])
  const newTotal = editedOrderItems.reduce((sum, item) => sum + newAmount * item.price, 0) + item.deliveryFee
  const hasIncreased = newAmount > item.orderItemsCatering[index].amount

    setErrorIndex(prevIndex => {
      return hasIncreased && item.paymentProviderType === 'Payex' && newTotal > originalTotalPrice || newAmount.toString() === '' ? 
      [...prevIndex, index] :
      prevIndex.filter(changedIndex => changedIndex !== index)
    })

    setEditedOrderItems((prevItems) => 
    prevItems.map((item) => (item.id === id ? { ...item, amount: newAmount } : item)))

    if (hideItem) {
      setHideItems((prevHideItem) => [...prevHideItem, id])
    }
}

const closeModal = () => {
  if ((amountIsUpdated || isDeliveryDateUpdated || isDeliveryTimeSlotUpdated || (item.storeComment !== null && item.storeComment !== localComment)) && editMode) {
    setSaveChangesModal(true) 
  } else if ((orderNeedsReprinting || productionListNeedsReprinting) && today <= deliveryDate) {
    togglePrintCheckboxes()
    setPrintReminderModal(true)
  }
  else {
    setActiveModal(null) 
    setEditMode(false)
    setUpdateDelivery(false)
  }
}

const cancelEditMode = () => {
  setEditMode(false)
  setEditedOrderItems(item.orderItemsCatering)
  setUpdateDelivery(false)
  setUpdatedDeliveryDate(new Date(item.deliveryDate))
  setCurrentMonth(new Date(item.deliveryDate))
  setHideItems([])
}

const confirmCancelEditMode = () => {
  setError({ errorText: '', isError: false })
  setSaveChangesModal(false)
  setEditMode(false)
  setEditedOrderItems(item.orderItemsCatering)
  setLocalComment(item.storeComment as string)
  setUpdatedDeliveryDate(new Date(item.deliveryDate))
  setUpdateDelivery(false) 
  setActiveModal(null)
  setCurrentMonth(new Date(item.deliveryDate))
  setHideItems([])
}

const handleEditOrderClick = () => {
  setEditMode(true)
  setErrorIndex([])
  setError({ errorText: '', isError: false })
  setUpdateDelivery(false)
}

const handleCheckboxChange = (id: string) => {
  setCheckboxes((prevCheckboxes) => 
  prevCheckboxes.map((checkbox) =>
  checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox))
}

const handleCheckboxSubmit = () => {
  const isCheckbox1Checked = checkboxes.some(checkbox => checkbox.id === '1' && checkbox.checked)
  const isCheckbox2Checked = checkboxes.some(checkbox => checkbox.id === '2' && checkbox.checked)
  
  if (isCheckbox1Checked && isCheckbox2Checked) {
    printOrderAndList()
    setUpdateDelivery(false)
  } else {
    checkboxes.forEach((checkbox) => {
      if (checkbox.id === '1' && checkbox.checked === true) {
        onPrintStatus()
      } else if (checkbox.id === '2' && checkbox.checked === true) {
        reprintProductionList()
      }
    })
    setPrintReminderModal(false)
    setActiveModal(null)
    setUpdateDelivery(false)
    setCheckboxes(prevCheckboxes => 
      prevCheckboxes.map(checkbox => ({ ...checkbox, checked: false }))
     )   
  }
}

const handleUpdateDeliveryDate = (date: Date) => {
  setUpdatedDeliveryDate(date)
}

const handleUpdateDeliveryTimeSlot = (timeSlot: string) => {
  setUpdatedTimeSlot(timeSlot)
}

const printOrderAndList = async () => {
  await onPrintStatus()
  reprintProductionList()
  setPrintReminderModal(false)
  setActiveModal(null)
  setCheckboxes(prevCheckboxes => 
    prevCheckboxes.map(checkbox => ({ ...checkbox, checked: false }))
   )   
}

  React.useEffect(() => {
    const timer = setTimeout(() => setEmailCopied(false), 3000)
    return () => {
      clearTimeout(timer)
    }
  }, [emailCopied])

  return (
    <>
      <li>
        <div className={styles.item} onClick={(event) => onOpenDetail(event)}>
          <div className={styles.text}>
            <div className={styles.orderNo}>
              <OrderNumber
                color={orderNoColor}
                textColor={status === 5 ? '#bdbbb9' : '#FFFFFF'}
                number={orderNo}
                dynamicWidth={true}
              />
            </div>
            <div className={styles.articles}>
              <span>{getArticleText()}</span>
            </div>
            <div className={styles.paymentStatus}>
              {paymentMethod}
              {paymentMethod !== 'Betala i butik' && `: ${paymentInfo}`}
              {paymentMethod === 'Betala i butik' && paymentStatus === 400 && ': Avbruten'}
            </div>
            <div className={styles.deliveryTime}>
              {deliveryBongDisplayName
                ? deliveryBongDisplayName
                : deliveryDisplayName}
              {deliveryTimeSlot ? `: ${item.deliveryTimeSlot}` : ''}
            </div>

            <div className={styles.printStatus}>
              {(printedOrder || printedCateringOrder()) && !orderNeedsReprinting ? (
                <>
                  <div className={styles.printedLabel}>
                    <div className={styles.checkIconBox}>
                      <CheckIcon className={styles.checkIcon} color='#2F6147' />
                    </div>
                    Utskriven
                  </div>
                  <button onClick={onPrintStatus} className={styles.printedButton}>
                    <div className={styles.printIconBox}>
                      <PrintIcon color='#cf2e05' className={styles.printIcon} />
                    </div>
                  </button>
                </>
              ) : (
                <button
                  onClick={onPrintStatus}
                  className={styles.printedButton}
                >
                  <div className={styles.printIconBox}>
                    <PrintIcon color='#cf2e05' className={styles.printIcon} />
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </li>
      <Modal
        active={activeConfirmationModal}
        onCancel={() => setActiveConfirmationModal(false)}
        maxWidth='762px'
        withClose={false}
      >
        <div className={styles.content}>
          <div className={styles.smallModalContent}>
            <div className={styles.confirmModalInfo}>
              <button
                className={styles.closeButtonConfirm}
                onClick={() => onDoNotCancel()}
                type='button'
              >
                <CloseIcon className={styles.closeIcon} color='var(--redMid)' />
              </button>
              <h3 className={styles.confirmModalHeading}>
                Är du säker på att du vill avbryta denna order?
              </h3>
              <p className={styles.confirmModalText}>
                Om du avbryter denna order kommer den att makuleras och läggas
                som makulerad under orderhistorik.{' '}
              </p>
            </div>
            <div className={styles.confirmModalButtons}>
              <button
                className={styles.confirmModalButton}
                id={styles.yesButton}
                onClick={() => onCancelOrder()}
              >
                Ja, avbryt denna order
              </button>
              <button
                className={styles.confirmModalButton}
                id={styles.noButton}
                onClick={() => onDoNotCancel()}
              >
                Nej, avbryt inte order
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        active={printReminderModal}
        onCancel={() => setPrintReminderModal(false)}
        maxWidth='762px'
        zIndex='150'
        withClose={false}
      >
        <div className={styles.content}>
          <div className={styles.smallModalContent}>
            <div className={styles.confirmModalInfo}>
              <button
                className={styles.closeButtonConfirm}
                onClick={() => onDoNotPrint()}
                type='button'
              >
                <CloseIcon className={styles.closeIcon} color='var(--red)' />
              </button>
              <h3 className={styles.confirmModalHeading}>
                Glöm inte att skriva ut uppdaterad order
              </h3>
              <p className={styles.confirmModalText}>
                Denna order finns redan utskriven. Glöm inte att skriva ut uppdaterad order på nytt.{' '}
              </p>
            </div>
            <div className={styles.checkboxes}>
              {checkboxes.map((checkbox) => (
                <Checkbox key={checkbox.id} id={checkbox.id} label={checkbox.label} checked={checkbox.checked} isActive={checkbox.isActive} onChange={handleCheckboxChange}/>
                  )
              )}
            </div>
            <div className={styles.confirmModalButtons}>
              <button
                className={styles.confirmModalButton}
                id={styles.yesButton}
                onClick={handleCheckboxSubmit}
                disabled={checkboxes.every(checkbox =>!checkbox.checked)}
              >
                Skriv ut 
              </button>
              <button
                className={styles.confirmModalButton}
                id={styles.noButton}
                onClick={() => onDoNotPrint()}
              >
                Nej, jag skriver ut senare
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        active={saveChangesModal}
        onCancel={() => setSaveChangesModal(false)}
        withClose={false}
        maxWidth='762px'
        zIndex='150'
      >
        <div className={styles.content}>
          <div className={styles.smallModalContent}>
            <div className={styles.confirmModalInfo}>
              <button
                className={styles.closeButtonConfirm}
                onClick={() => setSaveChangesModal(false)}
                type='button'
              >
                <CloseIcon className={styles.closeIcon} color='var(--red)' />
              </button>
              <h3 className={styles.confirmModalHeading}>
                Vill du spara ändringar av din order?
              </h3>
              <p className={styles.confirmModalText}>
                Om du avbryter kommer genomförda ändringar på denna order inte att sparas.{' '}
              </p>
            </div>
            <div className={styles.confirmModalButtons}>
              <button
                className={styles.confirmModalButton}
                id={styles.yesButton}
                onClick={() => { setSaveChangesModal(false); handleSave()}}
              >
                Ja, spara ändringar
              </button>
              <button
                className={styles.confirmModalButton}
                id={styles.noButton}
                onClick={() => confirmCancelEditMode()}
              >
                Nej, avbryt
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        maxWidth='930px'
        overflow='hidden'
        active={modalIsActive}
        onConfirm={() => null}
        onCancel={() => {
          if (editMode) {
            closeModal() 
          } else { 
          resetSingleModal(false)
          setActiveModal(null)
          }
        }}
        withClose={false}
      >
        <div className={styles.content}>
          {!singleModal && !editMode && (
          <button
              className={styles.prevButton}
              disabled={!prevId}
              onClick={() => setActiveModal(prevId)}
              type='button'
            >
            <AngleIcon
                className={styles.prevIcon}
                direction='left'
                color='currentColor'
              />
            <span className={styles.hidden}>Föregående</span>
          </button>
          )}
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <OrderNumber
                color={orderNoColor}
                textColor={status === 5 ? '#bdbbb9' : '#FFFFFF'}
                number={orderNo}
                fontSize='1.5rem'
                height='40px'
              />
              <div className={styles.paymentMethod}>{paymentMethod}</div>
              <Contact
                name={userName}
                phoneNumber={phoneNumber}
                street={street}
                city={city}
                email={email}
                deliveryType={deliveryDisplayName}
                deliveryFee={deliveryFee}
              />
              <button
                className={styles.closeButton}
                onClick={() =>  { resetSingleModal(false); closeModal() }}
                type='button'
              >
                <CloseIcon className={styles.closeIcon} color='#cf2e05' />
              </button>
            </div>
            {((printedOrder || printedCateringOrder()) && orderNeedsReprinting && today <= deliveryDate && !editMode) && (
              <div className={styles.printWarning}>
                <Banner theme="warning">
                  <div className={styles.warning}>
                    <span>
                      Skriv ut på nytt
                    </span>
                    <span className={styles.warningInfo}>
                      Nedan order är utskriven sedan tidigare men har blivit ändrad efter utskrift
                    </span>
                  </div>
                </Banner>
              </div>
            )}
            { !updateDelivery ? (
              <div className={styles.order}>
                {(showUpdatedOrder && orderHistory && !editMode) && (
                <OrderChange orderHistory={orderHistory} storeComment={item.storeComment as string}/>
                        )}
                <h4 className={styles.orderHeading}>
                  {!editMode ? 
                          orderHistory && orderHistory.length > 0 ? 'Uppdaterad order' : 'Order' 
                          : 'Ändra order'}
                </h4>
                {(item.storeComment && !editMode) && (
                  <Banner theme="info">
                    <span>
                      Butikskommentar: {item.storeComment}
                    </span>
                  </Banner>
                )}
                {(storeIds.includes(storeId) && today <= deliveryDate && (paymentProviderType === 'Payex' || error.isError)) &&
                         !((error.errorType === 'General' && !editMode)) && ( 
                         <Banner theme={error.isError && editMode ? (['Payment', 'General'].includes(error.errorType as string) ? 'warning' 
                         : 'info') : 'info' } > 
                           <div className={styles.warning}> 
                             {paymentProviderType === 'Payex' && !error.isError && 
                           ( <span> Kortbetalning - Det går inte att genomföra ändringar som ökar det reserverade beloppet på {getFormattedPrice(originalTotalPrice)} </span> )} 
                             {error.isError && ( <> <span> {error.errorType === 'General' && 'Tekniskt fel'} 
                               {error.errorType === 'Payment' && `Kortbetalning - Det går inte att genomföra ändringar som ökar det reserverade beloppet på ${getFormattedPrice(originalTotalPrice)}`} 
                             </span> 
                               <span className={styles.warningInfo}> 
                                 {error.errorType === 'General' && 'Ändringarna kunde inte sparas'} </span> 
                             </> 
                            )} 
                           </div> 
                         </Banner> 
                )}
                <Banner theme="info">
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>
                      {deliveryTimeSlot
                                  ? 'Leveransdag och tid: '
                                  : 'Leveransdag: '}
                      {dayOfWeek}, {deliveryDate}
                      {deliveryTimeSlot ? `, ${item.deliveryTimeSlot}` : ''}
                    </span>
                    {editMode && <button className={styles.button} onClick={() => { setUpdateDelivery(true) }}>Ändra dag eller tid för leverans</button>}
                  </div>
                </Banner>
                <div className={styles.allItems}>
                  <div className={styles.modalOrderItems}>
                    {orderItemsCatering &&
                              item.orderItemsCatering.length > 0 &&
                              item.orderItemsCatering.map((orderItem, i) => (
                                <div key={i} className={styles.modalOrderItem}>
                                  {orderItem.amount > 0 && (
                                  <CateringItemDetail
                                     item={orderItem}
                                     orderHistory={item.orderHistory}
                                     editMode={editMode}
                                     error={error.isError && errorIndex.includes(i)}
                                     setError={setError}
                                     onAmountChange={(newAmount, id, hideItem) => handleAmountChange(i, newAmount as number, id, hideItem)}
                                     hideItems={hideItems}
                                  />
                                  )}
                                </div>
                              ))}
                  </div>
                  {totalDiscount > 0 ? (
                    <div className={styles.deliveryPrice}>
                      <div
                                className={styles.itemContent}
                                style={{ borderBottom: 0 }}
                              >
                        <span>Rabatt</span> <span>-{item.totalDiscount} kr</span>
                      </div>
                    </div>
                          ) : null}
                  <div className={styles.deliveryPrice}>
                    <div className={styles.itemContent}>
                      <span>Leveranskostnad</span> <span>{deliveryFee} kr</span>
                    </div>
                  </div>
                  <div className={styles.priceDetails}>
                    <div className={styles.itemContent}>
                      <div className={styles.priceLeft}>
                        <span className={styles.totalLabel}>Totalt</span>
                        <span className={styles.numberOfArticles}>
                          {getArticleText()}
                        </span>
                      </div>
                      <div className={styles.priceRight}>
                        <span className={styles.totalPrice} style={{ color: error.isError && editMode ? '#e3000b' : 'var(--text)' }}>
                          {getFormattedPrice(errorIndex.length > 0 && error.isError ? editedOrderItems.reduce((sum, item) => sum + item.amount * item.price, 0) + deliveryFee : totalPrice)}
                        </span>
                      </div>
                    </div>
                  </div>
                  {editMode && (
                  <div className={styles.comment}>
                    <label htmlFor="commentInput" className={styles.commentLabel}>{item.storeComment ? 'Ändra intern butikskommentar:' : 'Skriv en intern butikskommentar:'}</label>
                    <textarea id="commentInput" placeholder={item.storeComment ? item.storeComment : 'Skriv en intern butikskommentar'} className={styles.commentInput} value={localComment} onChange={e => setLocalComment(e.target.value)}></textarea>
                  </div>
                          )}
                  <div className={styles.orderInfo}>
                    <div className={styles.orderInfoSurface}>
                      <h3 className={styles.surfaceHeading}>Orderinformation</h3>
                      <ul className={styles.surfaceGroup}>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>Order-ID:</span>
                          <span className={styles.surfaceValue}>{orderNo}</span>
                        </li>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>Orderdatum:</span>
                          <span className={styles.surfaceValue}>{orderDate}</span>
                        </li>
                        {restaurantMessage && (
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>
                            Meddelande till butik:
                          </span>
                          <span className={styles.surfaceValue}>
                            {item.restaurantMessage}
                          </span>
                        </li>
                                )}
                      </ul>
                      <h3 className={styles.surfaceHeading}>Leveranssätt</h3>
                      <ul className={styles.surfaceGroup}>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>Leveranssätt:</span>
                          <span className={styles.surfaceValue}>
                            {deliveryDisplayName}
                          </span>
                        </li>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>
                            {deliveryTimeSlot
                                      ? 'Leveransdag och tid:'
                                      : 'Leveransdag:'}
                          </span>
                          <span className={styles.surfaceValue}>
                            {dayOfWeek}, {deliveryDate}
                            {deliveryTimeSlot ? `, ${item.deliveryTimeSlot}` : ''}
                          </span>
                        </li>
                        {street ? (
                          <li className={styles.surfaceRow}>
                            <span className={styles.surfaceLabel}>
                              Leveransadress
                            </span>
                            <span
                                      className={styles.surfaceValue}
                                    >{`${street}, ${postNumber} ${city}`}</span>
                          </li>
                                ) : null}
                      </ul>
                      <h3 className={styles.surfaceHeading}>Kunduppgifter</h3>
                      <ul className={styles.surfaceGroup}>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>Namn:</span>
                          <span className={styles.surfaceValue}>{userName}</span>
                        </li>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>
                            Telefonnummer:
                          </span>
                          <span className={styles.surfaceValue}>{phoneNumber}</span>
                        </li>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>Mejladress:</span>
                          <span className={styles.surfaceValue}>{email}</span>
                          <button
                                    className={
                                      emailCopied
                                        ? styles.buttonClicked
                                        : styles.button
                                    }
                                    onClick={() => {
                                      navigator.clipboard.writeText(email)
                                      setEmailCopied(true)
                                    }}
                                  >
                            {emailCopied ? (
                              <>
                                <CheckIcon
                                          className={styles.checkIcon}
                                          color='#fff'
                                        />{' '}
                                Mejladress kopierad
                              </>
                                    ) : (
                                      <>
                                        <CopyIcon
                                          className={styles.copyIcon}
                                          color='var(--red)'
                                        />{' '}
                                        Kopiera mejladress
                                      </>
                                    )}
                          </button>
                        </li>
                      </ul>
                      <h3 className={styles.surfaceHeading}>Betalning</h3>
                      <ul className={styles.surfaceGroup}>
                        <li className={styles.surfaceRow}>
                          <span className={styles.surfaceLabel}>Betalsätt:</span>
                          <span className={styles.surfaceValue}>
                            {paymentMethod}
                          </span>
                        </li>
                        {paymentMethod !== 'Betala i butik' && (
                        <>
                          <li className={styles.surfaceRow}>
                            <span className={styles.surfaceLabel}>
                              Betalning:
                            </span>
                            <span className={styles.surfaceValue}>
                              Sker på leveransdagen
                            </span>
                          </li>
                          <li className={styles.surfaceRow}>
                            <span className={styles.surfaceLabel}>
                              Betalstatus:
                            </span>
                            <span className={styles.surfaceValue}>
                              {paymentInfo}
                            </span>
                          </li>
                        </>
                                )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
             
            ) : (
              <UpdateDelivery 
                deliveryDate={deliveryDate}
                deliveryTimeSlot={deliveryTimeSlot}
                dayOfWeek={dayOfWeek} 
                openHours={openHours} 
                calenderIsVisible={updateDelivery}
                onUpdateDeliveryDate={handleUpdateDeliveryDate} 
                onUpdateDeliveryTimeSlot={handleUpdateDeliveryTimeSlot} 
              /> 
              )}
            <div className={styles.actions}>
              {!editMode && (
                <>
                  <TextButton
                  onClick={() => onOpenConfimationModal()}
                  text='Avbryt order'
                  hidden={today === deliveryDate || !onCancel}
                  dynamicBorder={true}
                  style={{ padding: '1.5rem' }}
                >
                    <span className={styles.iconWrapper}>
                      <TrashIcon color='#cf2e05' className={styles.trashIcon} />
                    </span>
                  </TextButton>
                  <TextButton
                  onClick={handleEditOrderClick}
                  text='Ändra order'
                  hidden={!storeIds.includes(storeId) || today >= deliveryDate || !onCancel}
                  style={{
                    padding: '1.5rem',
                    borderLeft: today >= deliveryDate ? '0' : '1px solid var(--graySpecial)'
                  }}
                >
                    <span className={styles.iconWrapper}>
                      <EditIcon color='#cf2e05' className={styles.editIcon} />
                    </span>
                  </TextButton>
                  <TextButton
                    onClick={onPrintStatus}
                    text='Skriv ut order'
                    style={{
                      padding: '1.5rem',
                      borderLeft: '1px solid var(--graySpecial)'
                    }}
                  >
                    <span className={styles.iconWrapper}>
                      <PrintIcon color='#cf2e05' className={styles.printIcon} />
                    </span>
                  </TextButton>
                </>
              )}
              {editMode && (
              <div className={styles.editOrderActions}>
                <button className={styles.cancelEditModeButton} onClick={cancelEditMode}>
                  Avbryt
                </button>
                <button className={styles.editOrderButton} 
                disabled={isSaving}
                onClick={handleSave}>
                  {isSaving ? 'Sparar...' : 'Spara ändringar'}
                </button>
              </div>
              )}
            </div>
          </div>
          {!singleModal && !editMode && (
            <button
              className={styles.nextButton}
              disabled={!nextId}
              onClick={() => setActiveModal(nextId)}
              type='button'
            >
              <AngleIcon
                className={styles.nextIcon}
                direction='right'
                color='currentColor'
              />
              <span className={styles.hidden}>Nästa</span>
            </button>
          )}
        </div>
      </Modal>
      <div ref={printOrderRef}>
        <PrintCateringOrder order={item} />
      </div>
      <div ref={printListRef}>
        {filteredProductionList && (
          <PrintCateringProductionList orders={filteredProductionList} date={item.deliveryDate} />
        )}
      </div>
    </>
  )
}

function mapState (state: RootState) {
  return {
    cateringOrders: state.order.cateringOrders,
    selectedStore: state.store.selectedStore
  }
}
function mapDispatch(dispatch: Dispatch) {
  return {
    editCateringOrder: dispatch.order.editCateringOrder
  }
}

export default connect(mapState, mapDispatch)(CateringOrderItem)