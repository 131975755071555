import * as React from 'react'
import styles from './RadioButton.module.css'

type RadioButtonT = {
  id: string,
  checked?: boolean,
  name?: string,
  children?: React.ReactNode
  onChange: () => void,
}

function RadioButton({ id, checked, name = 'radio', onChange, children }: RadioButtonT) {
  return (
    <>
      <input defaultChecked={checked} className={styles.input} id={id} name={name} type="radio" onChange={onChange} />
      <label className={styles.label} htmlFor={id}>
        <div className={styles.check}>
          <span className={styles.dot} />
        </div>
        {children}
      </label>
    </>
  )
}

export default RadioButton