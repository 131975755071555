import React, { SyntheticEvent } from 'react'
import { DateObj, useDayzed } from 'dayzed'
import CalendarIcon from '../icons/Calendar'
import AngleIcon from '../icons/Angle'
import styles from './DatePicker.module.css'

interface Props {
  isActive: boolean
  setIsActive?: () => void
  firstDayOfWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6
  onDateSelected: (selectedDate: DateObj, event: SyntheticEvent<Element, Event>) => void
  selected: Date
  minDate?: Date
  date?: Date
  hideButton?: boolean
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky'
}

function DatePicker(props: Props) {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed(props)
  const { isActive, setIsActive } = props
  
  const monthNamesShort = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December'
  ]
  const weekdayNamesShort = ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön']
  
  return (
    <div>
      <button className={styles.datePickerButton} onClick={setIsActive} type="button" style={{ display: props.hideButton ? 'none' : '' }}>
        <span>{props.selected.toLocaleDateString('sv-SE')}</span>
        <CalendarIcon color="#3a3a3a" className={styles.calendarIcon} />
      </button>
      {isActive === true && calendars.length > 0 && (
        <div className={styles.datePicker} style={{ position: props.position }}>
          {calendars.map(calendar => (
            <div key={`${calendar.month}${calendar.year}`}>
              <div className={styles.calendarHeading}>
                <button className={styles.prevButton} {...getBackProps({ calendars })}>
                  <span className={styles.hidden}>Föregående</span>
                  <span className={styles.prev}><AngleIcon className={styles.prevIcon} direction="left" color="#cf2e05" /></span>
                </button>
                <span className={styles.monthYear}>
                  <span className={styles.month}>{monthNamesShort[calendar.month]}</span> <span className={styles.year}>{calendar.year}</span>
                </span>
                <button className={styles.nextButton} {...getForwardProps({ calendars })}>
                  <span className={styles.hidden}>Nästa</span>
                  <span className={styles.next}><AngleIcon className={styles.nextIcon} direction="right" color="#cf2e05" /></span>
                  
                </button>
              </div>
              <div className={styles.weekdays}>
                {weekdayNamesShort.map(weekday => (
                  <div className={styles.weekdayLabel} key={`${calendar.month}${calendar.year}${weekday}`}>
                    {weekday}
                  </div>
                ))}
              </div>
              <div className={styles.monthSquares}>
                {calendar.weeks.map((week, weekIndex) =>
                  week.map((dateObj, index) => {
                    const key = `${calendar.month}${calendar.year}${weekIndex}${index}`
                    if (!dateObj) {
                      return (
                        <div className={styles.emptySquare} key={`${key}${key}${index}`} />
                      )
                    }
                    const { date, selected, selectable } = dateObj

                    return (
                      <>
                        {!selectable ? (
                          <button 
                            className={styles.notSelectableButton} 
                            key={`${key}${index}`}
                            {...getDateProps({ dateObj })}
                          >
                            {date.getDate()}
                          </button>
                        ) : (
                          <button
                            className={styles.dateButton}
                            key={`${key}${index}${index}`}
                            {...getDateProps({ dateObj })}
                          >
                            <span className={selected ? styles.dateButtonSelected : ''}> {date.getDate()}</span>
                       
                          </button>
                        )}
                      </>
                    )
                    
                  })
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default DatePicker